import React, { useState } from 'react';
import { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Customer, FieldProps } from '../../types';
import { Modal, Fade, Backdrop } from '@material-ui/core';
const API_URL = process.env.REACT_APP_REST_API_URL;
interface Props extends FieldProps<Customer> {
    className?: string;
    size?: string;
}
const useStyles = makeStyles(() =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            position: 'absolute',
            boxShadow: '5px',
            padding: '20px',
            backgroundColor: '#fff',
        },
    })
);
const ImageField: FC<Props> = ({ record, size = '25', className }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return record ? (
        <>
            <img
                onClick={handleOpen}
                alt=""
                src={`${API_URL}/files/${record.image}/view?size=${size}x${size}`}
                className={className}
            />
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <img
                            alt=""
                            src={`${API_URL}/files/${record.image}/view`}
                        />
                    </div>
                </Fade>
            </Modal>
        </>
    ) : null;
};

export default ImageField;
