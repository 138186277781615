import * as React from 'react';
import { SelectInput, useTranslate } from 'react-admin';

const toChoices = (items, translate) =>
    items.map(item => ({
        id: item,
        name: translate(`resources.quizzes.status.${item}`),
    }));
const QuizStatusInput = props => {
    const translate = useTranslate();

    return (
        <SelectInput
            choices={toChoices(['draft', 'published', 'finished'], translate)}
            initialValue="draft"
            {...props}
        />
    );
};

export default QuizStatusInput;
