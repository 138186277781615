import React, { Fragment } from 'react';
import { DateField, TextField } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
    Avatar,
    Typography,
} from '@material-ui/core';
import ImageField from './fields/ImageFIeld';
const useStyles = makeStyles(() =>
    createStyles({
        user: {
            alignItems: 'left',
        },
        admin: {
            float: 'right',
            backgroundColor: '#EEE',
            padding: '10px',
            borderRadius: '10px',
        },
        adminAvatar: {
            backgroundColor: 'orange',
        },
    })
);

const Message = ({ user, message }) => {
    const classes = useStyles();
    return message.type === 'user' ? (
        <ListItem>
            <ListItemIcon>
                <Avatar alt={user.name} src={user.profilePictureUrl} />
            </ListItemIcon>
            <ListItemText
                className={classes.user}
                primary={<TextField record={user} source="name" />}
                secondary={
                    <Fragment>
                        <DateField
                            record={message}
                            source="created_at"
                            showTime
                        />
                        <Typography
                            component="span"
                            display="block"
                            style={{ whiteSpace: 'pre' }}
                            variant="body2"
                            color="textPrimary"
                        >
                            {message.message}
                        </Typography>
                        {message.image ? (
                            <ImageField record={message} size="300" />
                        ) : null}
                    </Fragment>
                }
            ></ListItemText>
        </ListItem>
    ) : (
        <ListItem>
            <ListItemIcon>
                <Avatar alt="Admin" src={'A'} className={classes.adminAvatar} />
            </ListItemIcon>
            <ListItemText
                className={classes.admin}
                primary={<TextField source="Admin" />}
                secondary={
                    <Fragment>
                        <DateField
                            record={message}
                            source="created_at"
                            showTime
                        />
                        <Typography
                            component="span"
                            display="block"
                            style={{ whiteSpace: 'pre' }}
                            variant="body2"
                            color="textPrimary"
                        >
                            {message.message}
                        </Typography>
                        {message.image ? (
                            <ImageField record={message} size="300" />
                        ) : null}
                    </Fragment>
                }
            >
                {message.message}
            </ListItemText>
        </ListItem>
    );
};

export default Message;
