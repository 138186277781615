import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

import VideoList from './VideoList';
import VideoEdit from './VideoEdit';
import VideoCreate from './VideoCreate';

export default {
    list: VideoList,
    edit: VideoEdit,
    create: VideoCreate,
    icon: PlayCircleFilledIcon,
};
