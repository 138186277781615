import * as React from 'react';
import { FC } from 'react';
import {
    Edit,
    TextInput,
    ReferenceArrayInput,
    CheckboxGroupInput,
    TextField,
    Toolbar,
    FormWithRedirect,
    ArrayInput,
    SimpleFormIterator,
    required,
    minLength,
    maxLength,
    useTranslate,
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { Quiz, FieldProps } from '../types';
import Aside from './Aside';
import Question from './inputs/Question';
import QuizStatusInput from './inputs/QuizStatusInput';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        background: 'red',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px',
        fontSize: 24,
    },
});

const QuizEdit = (props: any) => {
    return (
        <Edit
            title={<QuizTitle />}
            aside={<Aside />}
            undoable={false}
            component="div"
            {...props}
        >
            <QuizForm />
        </Edit>
    );
};

const QuizTitle: FC<FieldProps<Quiz>> = ({ record }) =>
    record ? <TextField source="title" record={record} size="32" /> : null;

const QuizForm = (props: any) => {
    const validatetitle = [required(), minLength(1), maxLength(100)];
    const translate = useTranslate();
    const classes = useStyles();
    const can_edit = props.record.status === 'draft';
    return (
        <FormWithRedirect
            {...props}
            redirect="list"
            render={(formProps: any) => (
                <Card>
                    {!can_edit ? (
                        <Typography className={classes.root}>
                            {translate(
                                'resources.quizzes.messages.not_editable'
                            )}
                        </Typography>
                    ) : null}
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <QuizStatusInput
                                        source="status"
                                        label="resources.quizzes.fields.status"
                                    />
                                    <TextInput
                                        type="title"
                                        source="title"
                                        label="resources.quizzes.fields.title"
                                        validate={validatetitle}
                                        fullWidth
                                    />
                                    <ReferenceArrayInput
                                        source="teams"
                                        reference="teams"
                                    >
                                        <CheckboxGroupInput optionText="name" />
                                    </ReferenceArrayInput>
                                    <ArrayInput
                                        source="questions"
                                        label="resources.quizzes.fields.questions"
                                    >
                                        <SimpleFormIterator>
                                            <Question {...formProps} />
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Box>
                            </Box>
                        </CardContent>
                        {can_edit ? (
                            <Toolbar
                                record={formProps.record}
                                basePath={formProps.basePath}
                                invalid={formProps.invalid}
                                handleSubmit={formProps.handleSubmit}
                                saving={formProps.saving}
                                resource="quizzes"
                            />
                        ) : null}
                    </form>
                </Card>
            )}
        />
    );
};

export default QuizEdit;
