import React, { FC } from 'react';

import { List } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Divider, Grid, Paper } from '@material-ui/core';
import SingleUserConversation from './SingleUserConversation';
import UsersList from './UsersList';
import { useListContext } from 'react-admin';
import { Button, Toolbar } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
const useStyles = makeStyles({
    chatSection: {
        width: '100%',
        height: '80vh',
    },
    borderRight500: {
        borderRight: '1px solid #e0e0e0',
    },
    redText: {
        color: 'red',
        fontWeight: 'bold',
        fontSize: '1.2em',
    },
    selected: {
        backgroundColor: '#eee',
        fontWeight: 'bold',
    },
});
const UsersPagination = () => {
    const { page, perPage, total, setPage } = useListContext();
    const nbPages = Math.ceil(total / perPage) || 1;
    return nbPages > 1 ? (
        <Toolbar>
            {page > 1 && (
                <Button
                    color="primary"
                    key="prev"
                    onClick={() => setPage(page - 1)}
                >
                    <ChevronLeft />
                    Prev
                </Button>
            )}
            {page !== nbPages && (
                <Button
                    color="primary"
                    key="next"
                    onClick={() => setPage(page + 1)}
                >
                    Next
                    <ChevronRight />
                </Button>
            )}
        </Toolbar>
    ) : null;
};
const ConversationsList: FC<any> = props => {
    const classes = useStyles();

    return (
        <div>
            <Grid container>
                <Grid item={true} xs={12}>
                    <Typography variant="h5" className="header-message">
                        Conversations
                    </Typography>
                </Grid>
            </Grid>
            <Grid container component={Paper} className={classes.chatSection}>
                <Grid item={true} xs={3} className={classes.borderRight500}>
                    <List
                        {...props}
                        basePath="users"
                        resource="users"
                        sort={{ field: 'last_message_at', order: 'DESC' }}
                        filter={{ last_message_at: { $ne: null } }}
                        perPage={20}
                        pagination={<UsersPagination />}
                        exporter={false}
                        bulkActionButtons={false}
                        hasEdit={false}
                        hasCreate={false}
                        hasShow={false}
                        hasList={false}
                    >
                        <UsersList {...props} />
                    </List>
                </Grid>
                <Grid item={true} xs={9}>
                    <Divider />
                    <Grid container style={{ padding: '20px' }}>
                        <SingleUserConversation />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default ConversationsList;
