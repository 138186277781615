import * as React from 'react';
import { FileInput, FileField, useTranslate } from 'react-admin';

const API_URL = process.env.REACT_APP_REST_API_URL;
const MyFileInput = props => {
    const translate = useTranslate();
    const formatFile = value => {
        if (typeof value === 'string') {
            // Value is null or the url string from the backend,
            //wrap it in an object so the form input can handle it
            return {
                url: `${API_URL}/files/${value}/view`,
                id: value,
                title: translate(props.label),
            };
        } else if (Array.isArray(value)) {
            return value.map(el => {
                if (typeof el === 'string') {
                    // Value is null or the url string from the backend,
                    //wrap it in an object so the form input can handle it

                    return {
                        url: `${API_URL}/files/${el}/view`,
                        id: el,
                        title: translate(props.label),
                    };
                } else return el;
            });
        } else {
            // Else a new image is selected which results in a value object
            //already having a preview link under the url key
            return value;
        }
    };
    return (
        <FileInput format={formatFile} {...props}>
            <FileField source="url" title="title" />
        </FileInput>
    );
};

export default MyFileInput;
