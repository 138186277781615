import { TranslationMessages } from 'ra-core';
import frenchMessages from 'ra-language-french';

const customFrenchMessages: TranslationMessages = {
    ...frenchMessages,
    global: {
        auth: {
            email: 'Email',
            sign_in: 'Entrer',
            password: 'Mot de Pass',
            sign_in_error: "Erreur d'Authentification Failed",
        },
    },
    pos: {
        search: 'Rechercher',
        configuration: 'Configuration',
        language: 'Langue',
        theme: {
            name: 'Theme',
            light: 'Clair',
            dark: 'Obscur',
        },
        dashboard: {
            subscriptions: {
                title: "Nombre d'inscriptions",
                today: "Aujourd'hui",
                week: 'Cette Semaine',
                month: 'Ce Mois',
            },
            products: {
                title: 'Nombre de Produits Rajoutés',
                today: "Aujourd'hui",
                week: 'Cette Semaine',
                month: 'Ce Mois',
            },
            selles: {
                title: 'Nombre de Produits Vendus',
                today: "Aujourd'hui",
                week: 'Cette Semaine',
                month: 'Ce Mois',
            },
            ca: {
                title: "Chiffre d'Affaire en DH",
                today: "Aujourd'hui",
                week: 'Cette Semaine',
                month: 'Ce Mois',
            },

            month_history: "Chiffre d'affaire sur 30 jours",
            new_orders: 'Nouvelles commandes',
            pending_reviews: 'Commentaires à modérer',
            new_customers: 'Nouveaux clients',
            pending_orders: 'Commandes à traiter',
            order: {
                items:
                    'par %{customer_name}, un produit |||| par %{customer_name}, %{nb_items} produits',
            },
            welcome: {
                title: 'Bienvenue dans SONECT',
                subtitle: 'Ceci est le back-office SONECT..',
                aor_button: 'Site web de SONECT',
                demo_button: 'Code source de cette démo',
            },
        },
        menu: {
            sales: 'Ventes',
            prducts: 'Produits',
            users: 'Utilisateurs',
            delivery: 'Livraisons',
        },
    },
    resources: {
        users: {
            name: 'Client |||| Clients',
            fields: {
                name: 'Nom Complet',
                email: 'Email',
                phone: 'Tél',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                birthdate: 'date de Naissance',
                socialReason: 'Raison Sociale',
                created_at: "Date d'inscription",
                updated_at: 'Dernière mise à jour',
                accountEnabled: 'Activé',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
                count_quizzes: 'Nombre de Quizzes passés',
                score: 'Score',
                team: 'Equipe',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                quizzes: 'Quizzes',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
                team: 'Equipe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        admins: {
            name: 'Administrateur |||| Administrateurs',
            fields: {
                firstName: 'Prénom',
                lastName: 'Nom',
                email: 'Email',
                phone: 'Tél',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                socialReason: 'Raison Sociale',
                created_at: 'Crée le',
                updated_at: 'Dernière mise à jour',
                accountEnabled: 'Activé',
                commands: 'Commandes',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        banners: {
            name: 'Bannière |||| Bannières',
            fields: {
                name: 'Nom',
                image: 'Image',
                target: 'Cible',
                targetModel: 'Type',
                category: 'Catégorie',
                feature: 'Feature',
            },
            image: {
                upload_several:
                    'Glissez des photos à uploader ou cliquez pour en sélectionner une.',
                upload_single:
                    'Glissez une photo à uploader ou cliquez pour la sélectionner.',
            },
        },
        posts: {
            name: 'Actvitié |||| Actvitiés',
            fields: {
                title: 'Titre',
                short_description: 'Description courte',
                content: 'Contenue',
                image: 'Image',
                file: 'Document',
                is_published: 'Publié',
                created_at: 'Date de Création',
            },
        },
        prducts: {
            name: 'Produit |||| Produits',
            fields: {
                title: 'Titre',
                file1: 'Formation Produit',
                file2: 'Fiche posologie',
                file3: 'Mentions légales',
                is_published: 'Publié',
                created_at: 'Date de Création',
            },
        },
        pcs: {
            name: 'Performance Commerciale |||| Performances Commerciales',
            fields: {
                title: 'Titre',
                file: 'Fichier',
                is_published: 'Publié',
                created_at: 'Date de Création',
            },
        },
        quizzes: {
            name: 'Quiz |||| Quizzes',
            fields: {
                is_enabled: 'Est activé',
                title: 'Titre',
                status: 'Statut',
                questions: 'Questions',
                questions_count: 'Nombre de questions',
                submits_count: "Nomber d'envoies",
                created_at: 'Date de Création',
                submits: 'Réponses',
            },
            status: {
                draft: 'Brouillon',
                published: 'Publié',
                finished: 'Terminé',
            },
            messages: {
                not_editable:
                    "Vous ne pouvez pas Modifier ce Quiz parce qu'il a des envoies enregistrés",
            },
            actions: {
                submits: 'Consulter Réponses',
                edit: 'Editer',
            },
        },
        submits: {
            name: 'Envoie Réponses |||| Envoies Réponses',
            fields: {
                user: 'Utilisateur',
                score_string: 'Score',
                created_at: "Date d'envoie",
                response: {
                    is_correct: 'Est Correct?',
                    response: 'Réponse',
                    question: 'Question',
                },
            },
            status: {
                draft: 'Brouillon',
                published: 'Publié',
                finished: 'Terminé',
            },
            messages: {
                not_editable:
                    "Vous ne pouvez pas Modifier ce Quiz parce qu'il a des envoies enregistrés",
            },
        },
        chat: {
            name: 'Conversation |||| Conversations',
            fields: {
                unread_messages: 'Messages non lus',
                last_message_at: 'Dernier message',
            },
        },
        teams: {
            name: 'Equipe |||| Equipes',
            all: 'Toutes les équipes',
            fields: {
                name: 'Nom Equipe',
            },
        },
        videos: {
            name: 'Vidéo |||| Vidéos',
            fields: {
                title: 'Titre',
                description: 'Description',
                image: 'Image',
                file: 'Fichier Vidéo',
                is_published: 'Publié',
                created_at: 'Date de Création',
            },
            actions: {
                open: 'Ouvrire',
                edit: 'Editer',
            },
        },
    },
};

export default customFrenchMessages;
