import * as React from 'react';
import { RaBox, BoxedShowLayout } from 'ra-compact-ui';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
    Show,
    BooleanField,
    TextField,
    DateField,
    ArrayField,
    Datagrid,
} from 'react-admin';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sidebar: {
            backgroundColor: '#eee',
            fontWeight: 'bold',
            padding: '10px',
        },
    })
);
const QuizShow = (props: any) => {
    const classes = useStyles({});
    return (
        <Show {...props} title="Réponses Quiz">
            <BoxedShowLayout>
                <RaBox display="flex" flexDirection="row">
                    <RaBox
                        display="flex"
                        flexDirection="column"
                        className={classes.sidebar}
                    >
                        <DateField
                            source="created_at"
                            label="resources.submits.fields.created_at"
                        />
                        <TextField
                            source="score_string"
                            label="resources.submits.fields.score_string"
                        />
                    </RaBox>
                    <RaBox>
                        <ResponsesShowLayout />
                    </RaBox>
                </RaBox>
            </BoxedShowLayout>
        </Show>
    );
};

const ResponsesShowLayout = props => {
    const { record } = props;
    const quiz = record ? record.quiz : null;
    return (
        <ArrayField {...props} source="responses">
            <Datagrid>
                <QuestionField
                    quiz={quiz}
                    record={record}
                    label="resources.submits.fields.response.question"
                />
                <TextField
                    source="response"
                    label="resources.submits.fields.response.response"
                />
                <BooleanField
                    source="is_correct"
                    label="resources.submits.fields.response.is_correct"
                />
            </Datagrid>
        </ArrayField>
    );
};
const QuestionField = ({ quiz, record }) => {
    const questionId = record.question;
    const questionText = quiz.questions.find(q => q._id === questionId).title;
    return <p>{questionText}</p>;
};
QuestionField.defaultProps = {
    source: 'name',
    label: 'Question',
};
export default QuizShow;
