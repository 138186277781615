import ListAltIcon from '@material-ui/icons/ListAlt';

import PrductList from './PrductList';
import PrductEdit from './PrductEdit';
import PrductCreate from './PrductCreate';

export default {
    list: PrductList,
    edit: PrductEdit,
    create: PrductCreate,
    icon: ListAltIcon,
};
