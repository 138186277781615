import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../types';
import { changeConversation } from './actions';
import {
    DateField,
    TextField,
    useListContext,
    useDataProvider,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Avatar,
    Badge,
    Link,
} from '@material-ui/core';
interface State {
    unread_messages?: number;
}
const useStyles = makeStyles({
    chatSection: {
        width: '100%',
        height: '80vh',
    },
    borderRight500: {
        borderRight: '1px solid #e0e0e0',
    },
    redText: {
        backgroundColor: '#ab003c',
        color: 'white',
        borderRadius: '50%',
        fontWeight: 'bold',
        padding: '0.5em',
    },
    selected: {
        backgroundColor: '#eee',
        fontWeight: 'bold',
    },
});
const OneUser = user => {
    const { id, name, profilePictureUrl, unread_messages } = user;
    const dispatch = useDispatch();
    const currentId = useSelector((state: AppState) => state.user);
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [state, setState] = useState<State>({
        unread_messages: unread_messages,
    });
    const handleClick = async id => {
        //SEND MARK AS READ BE.
        const { data: resp } = await dataProvider.create('chat/user', {
            data: { id },
        });
        if (resp) {
            setState(state => ({
                ...state,
                unread_messages: 0,
            }));
        }
        dispatch(changeConversation(id));
        //TODO: Remove unread messages
    };
    return (
        <Link onClick={() => handleClick(id)}>
            <ListItem
                button
                key={id}
                className={currentId === id ? classes.selected : ''}
            >
                <ListItemIcon>
                    <Avatar alt={name} src={profilePictureUrl} />
                </ListItemIcon>
                <ListItemText
                    primary={<TextField record={user} source="name" />}
                    secondary={
                        <DateField record={user} source="last_message_at" />
                    }
                />
                <ListItemSecondaryAction>
                    {state.unread_messages ? (
                        <Badge
                            badgeContent={state.unread_messages}
                            color="primary"
                        />
                    ) : null}
                </ListItemSecondaryAction>
            </ListItem>
        </Link>
    );
};
const UsersList = props => {
    const { ids, data } = useListContext();

    return (
        <List>
            {ids.map(id => (
                <OneUser key={id} {...data[id]} />
            ))}
        </List>
    );
};

export default UsersList;
