import React from 'react';
import { Datagrid, TextField, Filter, List, SearchInput } from 'react-admin';
const TeamFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);
const TeamList = (props: any) => {
    return (
        <List
            {...props}
            filters={<TeamFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick="edit">
                <TextField source="name" label="resources.teams.fields.name" />
            </Datagrid>
        </List>
    );
};

export default TeamList;
