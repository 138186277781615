import { Reducer } from 'redux';
import { CHANGE_CONVERSATION, changeConversation } from './actions';

type State = String;
type Action =
    | ReturnType<typeof changeConversation>
    | { type: 'OTHER_ACTION'; payload?: any };

const conversationsReducer: Reducer<State, Action> = (
    previousState = '',
    action
) => {
    if (
        action.type === CHANGE_CONVERSATION &&
        action.payload !== previousState
    ) {
        return action.payload;
    }
    return previousState;
};

export default conversationsReducer;
