import React, { useState, useEffect, useCallback, FC } from 'react';
import { useVersion, useDataProvider, useTranslate } from 'react-admin';
import { Card, CardContent, Typography } from '@material-ui/core';

import NbSubscriptions from './NbSubscriptions';

interface State {
    nbTodaySubscriptions?: number;
    nbThisWeekSubscriptions?: number;
    nbThisMonthSubscriptions?: number;

    nbTodayProducts?: number;
    nbThisWeekProducts?: number;
    nbThisMonthProducts?: number;

    nbTodaySelles?: number;
    nbThisWeekSelles?: number;
    nbThisMonthSelles?: number;

    todayCA?: number;
    thisWeekCA?: number;
    thisMonthCA?: number;
}

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
    sectiontitle: { fontSize: 14 },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const Dashboard: FC = () => {
    const [state, setState] = useState<State>({});
    const version = useVersion();
    const dataProvider = useDataProvider();
    const translate = useTranslate();

    // build dates
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    var day = today.getDay();
    const diff = today.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    const lastMonday = new Date(today.setDate(diff));

    const fetchUsers = useCallback(async () => {
        let query = {
            filter: {},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 1 },
            select: 'name',
        };
        // Get Today's subscriptions
        query.filter = { date_gte: today.toISOString() };
        const { total: nbTodaySubscriptions } = await dataProvider.getList(
            'users',
            query
        );
        // get this months subscriptions
        query.filter = { date_gte: firstDayOfMonth.toISOString() };
        const { total: nbThisMonthSubscriptions } = await dataProvider.getList(
            'users',
            query
        );
        // Get this week's subscriptions
        query.filter = { date_gte: lastMonday.toISOString() };
        const { total: nbThisWeekSubscriptions } = await dataProvider.getList(
            'users',
            query
        );

        setState(state => ({
            ...state,
            nbTodaySubscriptions,
            nbThisWeekSubscriptions,
            nbThisMonthSubscriptions,
        }));
    }, [dataProvider, today, firstDayOfMonth, lastMonday]);

    useEffect(() => {
        fetchUsers();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {/*<Welcome />*/}
            <Card>
                <CardContent>
                    <Typography variant="h4" component="h4">
                        {translate('pos.dashboard.subscriptions.title')}
                    </Typography>
                    <div style={styles.flex}>
                        <NbSubscriptions
                            label="pos.dashboard.subscriptions.today"
                            value={state.nbTodaySubscriptions}
                        />
                        <Spacer />
                        <NbSubscriptions
                            label="pos.dashboard.subscriptions.week"
                            value={state.nbThisWeekSubscriptions}
                        />
                        <Spacer />
                        <NbSubscriptions
                            label="pos.dashboard.subscriptions.month"
                            value={state.nbThisMonthSubscriptions}
                        />
                    </div>
                </CardContent>
            </Card>
            <VerticalSpacer />
        </>
    );
};

export default Dashboard;
