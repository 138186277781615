import * as React from 'react';
import { FC } from 'react';
import {
    Create,
    TextInput,
    BooleanInput,
    ReferenceArrayInput,
    CheckboxGroupInput,
    TextField,
    Toolbar,
    FormWithRedirect,
    required,
    minLength,
    maxLength,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import { Prduct, FieldProps } from '../types';
import MyFileInput from '../inputs/MyFileInput';

const PrductCreate = (props: any) => {
    return (
        <Create title={<PrductTitle />} component="div" {...props}>
            <PrductForm />
        </Create>
    );
};

const PrductTitle: FC<FieldProps<Prduct>> = ({ record }) =>
    record ? <TextField source="title" record={record} size="32" /> : null;

const PrductForm = (props: any) => {
    const validatetitle = [required(), minLength(1), maxLength(30)];
    const validateFile = [];
    const validatePublished = [];

    return (
        <FormWithRedirect
            {...props}
            redirect="list"
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <BooleanInput
                                        source="is_published"
                                        label="resources.prducts.fields.is_published"
                                        validate={validatePublished}
                                    />
                                    <TextInput
                                        type="title"
                                        source="title"
                                        resource="prducts"
                                        label="resources.prducts.fields.title"
                                        validate={validatetitle}
                                        fullWidth
                                    />
                                    <ReferenceArrayInput
                                        source="teams"
                                        reference="teams"
                                    >
                                        <CheckboxGroupInput optionText="name" />
                                    </ReferenceArrayInput>
                                    <MyFileInput
                                        validate={validateFile}
                                        source="file1.file"
                                        resource="prducts"
                                        label="resources.prducts.fields.file1"
                                        accept="application/pdf"
                                    />

                                    <MyFileInput
                                        validate={validateFile}
                                        source="file2.file"
                                        resource="prducts"
                                        label="resources.prducts.fields.file2"
                                        accept="application/pdf"
                                    />

                                    <MyFileInput
                                        validate={validateFile}
                                        source="file3.file"
                                        resource="prducts"
                                        label="resources.prducts.fields.file3"
                                        accept="application/pdf"
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="prducts"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

export default PrductCreate;
