import * as React from 'react';
import { FC } from 'react';
import {
    Create,
    TextInput,
    ReferenceArrayInput,
    CheckboxGroupInput,
    TextField,
    Toolbar,
    FormWithRedirect,
    ArrayInput,
    SimpleFormIterator,
    required,
    minLength,
    maxLength,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import { Quiz, FieldProps } from '../types';
import Question from './inputs/Question';
import QuizStatusInput from './inputs/QuizStatusInput';

const QuizCreate = (props: any) => {
    return (
        <Create title={<QuizTitle />} component="div" {...props}>
            <QuizForm />
        </Create>
    );
};

const QuizTitle: FC<FieldProps<Quiz>> = ({ record }) =>
    record ? <TextField source="title" record={record} size="32" /> : null;

const QuizForm = (props: any) => {
    const validatetitle = [required(), minLength(1), maxLength(100)];

    return (
        <FormWithRedirect
            {...props}
            redirect="list"
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <QuizStatusInput
                                        source="status"
                                        label="resources.quizzes.fields.status"
                                    />
                                    <TextInput
                                        type="title"
                                        source="title"
                                        label="resources.quizzes.fields.title"
                                        validate={validatetitle}
                                        fullWidth
                                    />
                                    <ReferenceArrayInput
                                        source="teams"
                                        reference="teams"
                                    >
                                        <CheckboxGroupInput optionText="name" />
                                    </ReferenceArrayInput>
                                    <ArrayInput
                                        source="questions"
                                        label="resources.quizzes.fields.questions"
                                    >
                                        <SimpleFormIterator>
                                            <Question {...formProps} />
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="quizzes"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

export default QuizCreate;
