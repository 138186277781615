import * as React from 'react';
import { FC } from 'react';
import Chip from '@material-ui/core/Chip';
import { useTranslate } from 'react-admin';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import { Record } from 'ra-core';
interface QuizProps {
    record?: Record;
    basePath?: string;
    label?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        finished: {
            color: 'white',
            backgroundColor: 'gray',
        },
        published: {
            color: 'white',
            backgroundColor: 'green',
        },
        draft: {
            color: 'white',
            backgroundColor: 'orange',
        },
    })
);
const QuizStatusField: FC<QuizProps> = ({ record }) => {
    const translate = useTranslate();

    const classes = useStyles({});
    if (record) {
        return (
            <Chip
                size="small"
                label={translate(`resources.quizzes.status.${record.status}`)}
                className={classes[record.status]}
            />
        );
    } else return null;
};
export default QuizStatusField;
