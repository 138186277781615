import * as React from 'react';
import { FC } from 'react';
import {
    Edit,
    TextInput,
    // BooleanInput,
    // ReferenceArrayInput,
    // CheckboxGroupInput,
    TextField,
    Toolbar,
    FormWithRedirect,
    required,
    minLength,
    maxLength,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import { Pc, FieldProps } from '../types';
import Aside from './Aside';
import MyFileInput from '../inputs/MyFileInput';

const PcEdit = (props: any) => {
    return (
        <Edit
            title={<PcTitle />}
            aside={<Aside />}
            undoable={false}
            component="div"
            {...props}
        >
            <PcForm />
        </Edit>
    );
};

const PcTitle: FC<FieldProps<Pc>> = ({ record }) =>
    record ? <TextField source="title" record={record} size="32" /> : null;

const PcForm = (props: any) => {
    const validatetitle = [required(), minLength(1), maxLength(30)];
    const validateFile = [required()];
    // const validatePublished = [];

    return (
        <FormWithRedirect
            {...props}
            redirect="list"
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    {/* <BooleanInput
                                        source="is_published"
                                        label="resources.pcs.fields.is_published"
                                        validate={validatePublished}
                                    /> */}
                                    <TextInput
                                        type="title"
                                        source="title"
                                        resource="pcs"
                                        label="resources.pcs.fields.title"
                                        validate={validatetitle}
                                        fullWidth
                                    />
                                    {/* <ReferenceArrayInput
                                        source="teams"
                                        reference="teams"
                                    >
                                        <CheckboxGroupInput optionText="name" />
                                    </ReferenceArrayInput> */}
                                    <MyFileInput
                                        validate={validateFile}
                                        source="file"
                                        resource="pcs"
                                        label="resources.pcs.fields.file"
                                        accept=".xls, .xlsx"
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="pcs"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

export default PcEdit;
