import LiveHelpIcon from '@material-ui/icons/LiveHelp';

import QuizList from './QuizList';
import QuizEdit from './QuizEdit';
import QuizCreate from './QuizCreate';

export default {
    list: QuizList,
    edit: QuizEdit,
    create: QuizCreate,
    icon: LiveHelpIcon,
};
