import * as React from 'react';
import { FC } from 'react';
import { Customer, FieldProps } from '../../types';

interface Props extends FieldProps<Customer> {
    className?: string;
    size?: string;
}

const Thumbnailfield: FC<Props> = ({ record, size = '25', className }) =>
    record ? (
        record.image ? (
            <img
                alt=""
                src={`${process.env.REACT_APP_REST_API_URL}/files/${record.image}/view?size=${size}x${size}`}
                style={{ height: parseInt(size, 16) }}
                className={className}
            />
        ) : (
            <img
                alt=""
                src={`${process.env.REACT_APP_PUBLIC_URL}${record.image_url}`}
                style={{ height: parseInt(size, 16) }}
                className={className}
            />
        )
    ) : null;

export default Thumbnailfield;
