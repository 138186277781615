import LiveHelpIcon from '@material-ui/icons/LiveHelp';

import SubmitList from './SubmitList';
import SubmitShow from './SubmitShow';

export default {
    list: SubmitList,
    show: SubmitShow,
    icon: LiveHelpIcon,
};
