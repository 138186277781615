import * as React from 'react';
import {
    TextInput,
    SelectInput,
    ArrayInput,
    SimpleFormIterator,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles({
    root: {
        background: '#EFEFEF',
        marginTop: 10,
    },
});
const toChoices = items => items.map(item => ({ id: item, name: item }));
const Question = props => {
    const { values } = useFormState();
    const id = props.id;
    const index = id.substring(id.indexOf('[') + 1, id.indexOf(']'));
    const choices =
        values.questions[index] && values.questions[index].choices
            ? values.questions[index].choices
            : [];
    const classes = useStyles();
    return (
        <Accordion className={classes.root}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <TextInput
                    fullWidth
                    source={id + '.title'}
                    label="Ennancé de la question"
                />
            </AccordionSummary>
            <AccordionDetails>
                <ArrayInput source={id + 'choices'} label="Choix">
                    <SimpleFormIterator>
                        <TextInput label="" />
                    </SimpleFormIterator>
                </ArrayInput>
            </AccordionDetails>
            <AccordionActions>
                <SelectInput
                    source={id + 'response'}
                    label="Réponse"
                    choices={toChoices(choices)}
                />
            </AccordionActions>
        </Accordion>
    );
};

export default Question;
