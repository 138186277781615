import React from 'react';
import { EditButton } from 'react-admin';
import {
    Datagrid,
    DateField,
    TextField,
    BooleanField,
    Filter,
    List,
    SearchInput,
    BooleanInput,
    ReferenceInput,
    SelectInput,
} from 'react-admin';
import VideoOpenBtn from './fields/VideoOpenBtn';
import ThumbnailField from './fields/ThumbnailField';
const VideoFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <BooleanInput
            source="is_published"
            label="resources.videos.fields.is_published"
        />
        <ReferenceInput
            alwaysOn
            emptyText="resources.teams.all"
            label="resources.users.fieldGroups.team"
            source="teams"
            reference="teams"
        >
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);
const VideoList = (props: any) => {
    return (
        <List
            {...props}
            filters={<VideoFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick="">
                <ThumbnailField
                    source="image"
                    label="resources.videos.fields.image"
                />
                <TextField
                    source="title"
                    label="resources.videos.fields.title"
                />
                <BooleanField
                    source="is_published"
                    label="resources.videos.fields.is_published"
                />
                <DateField
                    source="created_at"
                    type="date"
                    label="resources.videos.fields.created_at"
                />
                <EditButton label="resources.videos.actions.edit" />
                <VideoOpenBtn label="resources.videos.actions.open" />
            </Datagrid>
        </List>
    );
};

export default VideoList;
