import React, { useState, useEffect, useCallback, FC } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../types';

import { Loading, useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
    List as MUList,
    TextField as MUTextField,
    Grid,
    Fab,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from './Message';

interface MessageClass {
    message?: any;
    id?: any;
}
interface State {
    messages?: any;
    adminMessage?: String;
    user?: any;
    id?: any;
    loading?: boolean;
    sending?: boolean;
}
const useStyles = makeStyles({
    messageArea: {
        height: '60vh',
        overflowY: 'auto',
    },
    leftAlign: {
        alignItems: 'left',
    },
    rightAlign: {
        alignItems: 'right',
    },
});

const SingleUserConversation: FC<any> = () => {
    const classes = useStyles();
    const id = useSelector((state: AppState) => state.user);
    const [state, setState] = useState<State>({ loading: false });
    const dataProvider = useDataProvider();

    const fetchMessages = useCallback(async () => {
        if (id === '') return;
        setState(state => ({
            ...state,
            loading: true,
        }));
        let query = {
            filter: { user: id },
            sort: { field: 'created_at', order: 'DESC' },
            pagination: { page: 1, perPage: 50 },
            select: 'name',
        };
        const { data: messages } = await dataProvider.getList('chat', query);

        setState(state => ({
            ...state,
            messages,
            adminMessage: '',
            loading: false,
        }));
    }, [dataProvider, id]);
    const fetchUser = useCallback(async () => {
        if (id === '') return;
        setState(state => ({
            ...state,
            loading: true,
        }));

        const { data: user } = await dataProvider.getOne('users', {
            id: id,
        });
        setState(state => ({
            ...state,
            user,
        }));
    }, [dataProvider, id]);

    useEffect(() => {
        fetchUser();
        fetchMessages();
    }, [fetchMessages, fetchUser, id]);

    const handleChange = event => {
        if (event && event.target) {
            const { value } = event.target;
            setState(state => ({
                ...state,
                adminMessage: value,
            }));
        }
    };
    const handleSubmit = async event => {
        if (id === '') return;
        setState(state => ({
            ...state,
            sending: true,
        }));
        let data = {
            user: id,
            message: state.adminMessage,
        };
        const { data: message } = await dataProvider.create('chat', { data });
        setState(state => ({
            ...state,
            adminMessage: '',
            sending: false,
            messages: [
                { ...message, created_at: new Date() },
                ...state.messages,
            ],
        }));
    };

    // rendering
    if (state.loading) return <Loading />;
    return (
        <>
            <Grid item={true} xs={11}>
                {id ? (
                    <MUTextField
                        onChange={handleChange}
                        value={state.adminMessage}
                        id="outlined-basic-email"
                        label="Répondre"
                        multiline={true}
                        fullWidth
                    />
                ) : null}
                <MUList className={classes.messageArea}>
                    {state.messages && state.messages.length ? (
                        <>
                            {state.messages.map((m: MessageClass) => {
                                return (
                                    <Message
                                        key={m.id}
                                        user={state.user}
                                        message={m}
                                    />
                                );
                            })}
                        </>
                    ) : null}
                </MUList>
            </Grid>
            <Grid item={true} xs={1} className={classes.rightAlign}>
                {id ? (
                    !state.sending ? (
                        <Fab
                            color="secondary"
                            aria-label="add"
                            onClick={handleSubmit}
                            disabled={
                                !state.adminMessage ||
                                state.adminMessage.length === 0
                            }
                        >
                            <SendIcon />
                        </Fab>
                    ) : (
                        <CircularProgress />
                    )
                ) : null}
            </Grid>
        </>
    );
};
export default SingleUserConversation;
