import TrendingUpIcon from '@material-ui/icons/TrendingUp';

import PcList from './PcList';
import PcEdit from './PcEdit';
import PcCreate from './PcCreate';

export default {
    list: PcList,
    edit: PcEdit,
    create: PcCreate,
    icon: TrendingUpIcon,
};
