import * as React from 'react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useTranslate } from 'react-admin';
import { Record } from 'ra-core';

interface QuizProps {
    record?: Record;
    basePath?: string;
    label?: string;
}
const QuizSubmitsBtn: FC<QuizProps> = props => {
    const translate = useTranslate();
    const { record } = props;
    if (record) {
        return (
            <Button
                color="secondary"
                component={Link}
                to={{
                    pathname: '/submits',
                    search: `filter=${JSON.stringify({ quiz: record.id })}`,
                }}
            >
                {translate('resources.quizzes.fields.submits')}
            </Button>
        );
    } else return null;
};
export default QuizSubmitsBtn;
