import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    BooleanField,
    Filter,
    List,
    SearchInput,
    BooleanInput,
    ReferenceInput,
    SelectInput,
} from 'react-admin';
const PrductFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <BooleanInput
            source="is_published"
            label="resources.prducts.fields.is_published"
        />
        <ReferenceInput
            alwaysOn
            emptyText="resources.teams.all"
            label="resources.users.fieldGroups.team"
            source="teams"
            reference="teams"
        >
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);
const PostList = (props: any) => {
    return (
        <List
            {...props}
            filters={<PrductFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick="edit">
                <TextField
                    source="title"
                    label="resources.prducts.fields.title"
                />
                <BooleanField
                    source="is_published"
                    label="resources.prducts.fields.is_published"
                />
                <DateField
                    source="created_at"
                    type="date"
                    label="resources.prducts.fields.created_at"
                />
            </Datagrid>
        </List>
    );
};

export default PostList;
