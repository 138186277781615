import * as React from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { useMediaQuery, Theme } from '@material-ui/core';
import { useTranslate, MenuItemLink, DashboardMenuItem } from 'react-admin';

import users from '../users';
import posts from '../posts';
import prducts from '../prducts';
import pcs from '../pcs';
import quizzes from '../quizzes';
import chat from '../chat';
import teams from '../teams';
import videos from '../videos';

// import DashboardMenuItem from './DashboardMenuItem';

import { AppState } from '../types';

type MenuName = 'menuCatalog' | 'menuSales' | 'menuUsers' | 'menuDelivery';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    return (
        <div>
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            <MenuItemLink
                to={`/quizzes`}
                primaryText={translate(`resources.quizzes.name`, {
                    smart_count: 2,
                })}
                leftIcon={<quizzes.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/conversations`}
                primaryText={translate(`resources.chat.name`, {
                    smart_count: 2,
                })}
                leftIcon={<chat.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/prducts`}
                primaryText={translate(`resources.prducts.name`, {
                    smart_count: 2,
                })}
                leftIcon={<prducts.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/posts`}
                primaryText={translate(`resources.posts.name`, {
                    smart_count: 2,
                })}
                leftIcon={<posts.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/pcs`}
                primaryText={translate(`resources.pcs.name`, {
                    smart_count: 2,
                })}
                leftIcon={<pcs.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/videos`}
                primaryText={translate(`resources.videos.name`, {
                    smart_count: 2,
                })}
                leftIcon={<videos.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/teams`}
                primaryText={translate(`resources.teams.name`, {
                    smart_count: 2,
                })}
                leftIcon={<teams.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/users`}
                primaryText={translate(`resources.users.name`, {
                    smart_count: 2,
                })}
                leftIcon={<users.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />

            {isXSmall && logout}
        </div>
    );
};

export default Menu;
