import * as React from 'react';
import { FC } from 'react';
import {
    Create,
    TextInput,
    BooleanInput,
    ReferenceArrayInput,
    CheckboxGroupInput,
    TextField,
    Toolbar,
    FormWithRedirect,
    required,
    minLength,
    maxLength,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import { Video, FieldProps } from '../types';
import MyImageInput from '../inputs/MyImageInput';
import MyFileInput from '../inputs/MyFileInput';
const VideoCreate = (props: any) => {
    return (
        <Create title={<VideoTitle />} component="div" {...props}>
            <VideoForm />
        </Create>
    );
};

const VideoTitle: FC<FieldProps<Video>> = ({ record }) =>
    record ? <TextField record={record} size="32" /> : null;

const VideoForm = (props: any) => {
    const validatetitle = [required(), minLength(1), maxLength(30)];
    const validateDescription = [minLength(1)];
    const validateImage = [];
    const validateFile = [required()];
    const validatePublished = [];

    return (
        <FormWithRedirect
            {...props}
            redirect="list"
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <BooleanInput
                                        validate={validatePublished}
                                        type="is_published"
                                        source="is_published"
                                        resource="videos"
                                        label="resources.videos.fields.is_published"
                                    />
                                    <ReferenceArrayInput
                                        source="teams"
                                        reference="teams"
                                    >
                                        <CheckboxGroupInput optionText="name" />
                                    </ReferenceArrayInput>
                                    <TextInput
                                        validate={validatetitle}
                                        type="title"
                                        source="title"
                                        resource="videos"
                                        label="resources.videos.fields.title"
                                        fullWidth
                                    />
                                    <MyImageInput
                                        validate={validateImage}
                                        source="image"
                                        label="resources.videos.fields.image"
                                        accept="image/*"
                                    />
                                    <MyFileInput
                                        validate={validateFile}
                                        source="file"
                                        label="resources.videos.fields.file"
                                        accept="video/*"
                                    />
                                    <TextInput
                                        validate={validateDescription}
                                        multiline
                                        source="description"
                                        resource="videos"
                                        label="resources.videos.fields.description"
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="videos"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

export default VideoCreate;
