import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    NumberField,
    List,
    ReferenceField,
} from 'react-admin';

const PostList = (props: any) => {
    return (
        <List
            {...props}
            sort={{ field: 'created_at', order: 'DESC' }}
            // filter={{ quiz: quiz.id }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick="show">
                <ReferenceField
                    source="user"
                    reference="users"
                    label="resources.submits.fields.user"
                >
                    <TextField
                        source="name"
                        label="resources.users.fields.name"
                    />
                </ReferenceField>
                <DateField
                    source="created_at"
                    label="resources.submits.fields.created_at"
                />
                <NumberField
                    source="score.note"
                    options={{ style: 'percent' }}
                    label="resources.submits.fields.score_string"
                />
            </Datagrid>
        </List>
    );
};

export default PostList;
