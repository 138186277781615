import GroupIcon from '@material-ui/icons/Group';

import TeamList from './TeamList';
import TeamEdit from './TeamEdit';
import TeamCreate from './TeamCreate';

export default {
    list: TeamList,
    edit: TeamEdit,
    create: TeamCreate,
    icon: GroupIcon,
};
