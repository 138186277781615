import * as React from 'react';
import { FC } from 'react';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { useTranslate, Button } from 'react-admin';
import { Record } from 'ra-core';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

interface QuizProps {
    record?: Record;
    basePath?: string;
    label?: string;
}
const VideoOpenBtn: FC<QuizProps> = props => {
    const translate = useTranslate();
    const [showDialog, setShowDialog] = React.useState(false);

    const handleOpenDialog = () => setShowDialog(true);

    const handleCloseDialog = () => setShowDialog(false);

    const { record } = props;
    return record && record.video_url ? (
        <>
            <Button
                onClick={handleOpenDialog}
                label="resources.videos.actions.open"
            >
                <PlayCircleFilledIcon />
            </Button>

            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseDialog}
                aria-label="Video"
            >
                <DialogContent>
                    <video width="100%" controls>
                        <source
                            src={`${process.env.REACT_APP_PUBLIC_URL}${record.video_url}`}
                        />
                        Your browser does not support HTML video.
                    </video>
                </DialogContent>
            </Dialog>
        </>
    ) : null;
};
export default VideoOpenBtn;
